import './MemberIntro.scss';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import ContainerWYSIWYG from '../../components/ContainerWYSIWYG';
import PageLoader from '../../components/PageLoader';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientAnketaData from '../../hooks/useClientAnketaData';
import { useParams } from 'react-router-dom';

export const MemberIntro: React.FC<{}> = () => {
  const { anketaID } = useParams<{ anketaID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useClientAnketaData(anketaID);

  if (anketaDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (anketaData.status === 1 && !anketaData.questions_completed) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/questions/`} />;
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && !anketaData.anketa.interview_needless) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && !anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/call_schedulling/`} />;
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && anketaData.anketa.interview_needless) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo='/profile/' />;
  }

  return (
    <motion.main className='memberIntro' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Вступление к диагностике</Title>
      <div className='subTitle'>
        <br />
      </div>
      {anketaData.anketa.intro ? (
        <div className='introBlocksWrapper introBlocksWrapper--customIntro'>
          <ContainerWYSIWYG data={anketaData.anketa.intro} size='default' />
        </div>
      ) : (
        <div className='introBlocksWrapper'>
          <div className='introBlock'>
            <div className='blockTitle'>Рекомендации по заполнению анкеты</div>
            <div className='blockDescription'>
              На открытые вопросы старайтесь отвечать кратко, показывая логику рассуждений и объясняя, почему вы выбрали тот или иной вариант. Пишите кратко, но не
              упрощайте свои ответы. Нам важно увидеть ход ваших мыслей. <br /> Общее время, которое необходимо потратить на решение кейса, — около 3 часов.
              <br />
              <br />
              <b>ВАЖНО! </b>
              <br />
              Ваш ответ сохраняется, когда вы нажимаете на кнопку «Следующий вопрос». После того, как вы написали и сохранили ответ, вы не сможете вернуться и исправить
              его.
            </div>
          </div>
          <div className='introBlock'>
            <div className='blockTitle'>На что мы будем смотреть при оценке открытых вопросов?</div>
            <div className='blockDescription'>
              <ol>
                <li>
                  <span className='liNum'>1.</span>
                  <span className='liText'>
                    В первую очередь оцениваются ваши письменные ответы. Во время общения эксперт лишь валидирует их, поэтому дополнить ответ на обсуждении не получится.
                  </span>
                </li>
                <li>
                  <span className='liNum'>2.</span>
                  <span className='liText'>Ваши ответы должны быть по сути вопроса и показывать вашу профессиональную эрудицию.</span>
                </li>
                <li>
                  <span className='liNum'>3.</span>
                  <span className='liText'>В ответах в первую очередь оценивается логика рассуждений, основанная на данных, метриках и цифрах.</span>
                </li>
                <li>
                  <span className='liNum'>4.</span>
                  <span className='liText'>В данном тесте мы не оцениваем ваши Soft skills и то, как вы умеете работать с информацией.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='introBlock'>
            <div className='blockTitle'>Как будем оценивать ответы?</div>
            <div className='blockDescription'>
              <ol className='liDash'>
                <li>
                  <span className='liNum'>0&nbsp;&nbsp;— </span>
                  <span className='liText'>Нет знаний, не был дан ответ на поставленный вопрос, либо ответ слишком общий.</span>
                </li>
                <li>
                  <span className='liNum'>1&nbsp;&nbsp;— </span>
                  <span className='liText'>Есть базовые знания по теме.</span>
                </li>
                <li>
                  <span className='liNum'>2&nbsp;&nbsp;— </span>
                  <span className='liText'>
                    Хорошо ориентируется в вопросе, есть знания терминологии и методологии, в ответах присутствует конкретика, но есть что развивать.
                  </span>
                </li>
                <li>
                  <span className='liNum'>3&nbsp;&nbsp;— </span>
                  <span className='liText'>Очень хорошо знает тему: есть знания методологии и терминологии, а также понимание, как их применять на практике.</span>
                </li>
              </ol>
            </div>
          </div>
          <div className='introBlock'>
            <div className='blockTitle'>Если я получил низкий балл, что это значит?</div>
            <div className='blockDescription'>
              Бывает так, что вы добиваетесь высокого продуктового результата, выполняете все KPI's, но показываете низкие результаты при анкетировании. Это значит, что
              вы обладаете потенциалом, но он не реализован в полной мере. Обучение поможет вам найти точки профессионального и карьерного роста, а также научиться решать
              ваши профессиональные задачи более эффективно.
            </div>
          </div>
        </div>
      )}
      {/* <div className='introFooterText'>
        Данную оценку прошли уже более 1000 продактов различных компаний. <br />
        Система оценки построена на данных анализа более чем 20 000 продуктов разной стадии готовности.
      </div> */}
      <Button
        type='link'
        href={
          anketaData.inquirer
            ? `/client/anketa/${anketaID}/inquirer/`
            : anketaData.anketa.description
            ? `/client/anketa/${anketaID}/preview/`
            : `/client/anketa/${anketaID}/questions/`
        }
        width='330rem'
        size='large'
        color='blue'
        text='Понятно'
      />
    </motion.main>
  );
};
